<template>
  <dermato-history-template
    :treated-for-skin-cancer="treatedForSkinCancer"
    :glandular-cancer="glandularCancer"
    :breast-cancer="breastCancer"
    :breast-cancer-visible="breastCancerVisible"
    :has-herpes="hasHerpes"
    :suffered-from-acne-rosacea="sufferedFromAcneRosacea"
    :suffered-from-acne-rosacea-visible="sufferedFromAcneRosaceaVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import without from 'lodash/without';

import DermatoHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/dermato-history/DermatoHistoryTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { arrayNotEmpty } from '@/modules/dashboard/utils';
import { female } from '@/modules/questionnaire/api/helpers';

const FIELD_NAMES = [
  'treatedForSkinCancer',
  'glandularCancer',
  'breastCancer',
  'hasHerpes',
  'sufferedFromAcneRosacea'
];

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  field('age'),
  field('gender'),
  ...FIELD_NAMES.map(fieldName => requiredField(fieldName))
];

export default {
  name: 'DermatoHistory',
  components: { DermatoHistoryTemplate },
  mixins: [makeStep(FIELDS)],
  computed: {
    isFemale() {
      return female(this.gender);
    },
    visibleFields() {
      const fieldsToRemove = [];

      if (!this.breastCancerVisible) {
        fieldsToRemove.push('breastCancer');
      }

      if (!this.sufferedFromAcneRosaceaVisible) {
        fieldsToRemove.push('sufferedFromAcneRosacea');
      }

      return arrayNotEmpty(fieldsToRemove) ? without(FIELD_NAMES, ...fieldsToRemove) : FIELD_NAMES;
    },
    breastCancerVisible() {
      return this.isFemale && this.age > 24;
    },
    sufferedFromAcneRosaceaVisible() {
      return this.age >= 28;
    }
  },
  watch: {
    treatedForSkinCancer(newValue, oldValue) {
      if (!oldValue && this.isNextStepAvailable('treatedForSkinCancer')) {
        this.showNextStep();
      } else {
        this.scrollTo('#glandular-cancer');
      }
    },
    glandularCancer(newValue, oldValue) {
      if (!oldValue && this.isNextStepAvailable('glandularCancer')) {
        this.showNextStep();
      } else {
        this.scrollTo(this.breastCancerVisible ? '#breast-cancer' : '#herpes');
      }
    },
    breastCancer(newValue, oldValue) {
      if (!oldValue && this.isNextStepAvailable('breastCancer')) {
        this.showNextStep();
      } else {
        this.scrollTo('#herpes');
      }
    },
    hasHerpes(newValue, oldValue) {
      if (!oldValue && this.isNextStepAvailable('hasHerpes')) {
        this.showNextStep();
      } else if (this.age >= 28) {
        this.scrollTo('#acne-rosacea');
      }
    },
    sufferedFromAcneRosacea(newValue, oldValue) {
      if (!oldValue && this.isNextStepAvailable('sufferedFromAcneRosacea')) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isNextStepAvailable(changedField) {
      return this.visibleFields.every(fieldName => {
        if (fieldName !== changedField) {
          if (fieldName === 'sufferedFromAcneRosacea') {
            return this.age < 28 || !!this[fieldName];
          }

          return !!this[fieldName];
        }

        return true;
      });
    },
    isFieldVisible(fieldName) {
      if (fieldName === 'breastCancer') {
        return this.breastCancerVisible;
      }

      if (fieldName === 'sufferedFromAcneRosacea') {
        return this.sufferedFromAcneRosaceaVisible;
      }

      return true;
    }
  }
};
</script>
